  .dropdown-list{
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    text-align: center;
    width:160%;
  }
  .menu{
    color:#0158a1;
  }
  .dropdown-menu{
    transform:translate(0px,12px);
  }
  .courses-topics{
    list-style-type: none;
    padding:4px;
    padding-left: 12px;
    color:#551A8B;
    font-size: 17px;
    padding-bottom: 12px;
  }
  .closePopupButton {
    position: absolute;
    top: 10px; /* Adjust this value to change the vertical position */
    right: 10px; /* Adjust this value to change the horizontal position */
    cursor: pointer;
    color:#256dac;
    font-size: 30px;
  }
.courses-heading{
  text-align: center;
}
   .course-container{
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    width:80%;
    margin:auto;
    padding: 20px;
    align-items: center;
    cursor: pointer;
  } 
  .popup-Title{
    color:#256dac;
  text-align:center;
  }
  #List{
    color:#256dac;
  }
  .courseClose Button:hover{
     background-color: aqua; 
  color: black;
  }
  .modal-open{
    overflow: hidden;
  }
  @media screen and (max-width: 768px){
    .course-container{
      width:89vw;
      display: flex;
      flex-direction: column;
    }
    .dropdown-menu{
      transform: translate(0px, 31px);
      /* transform: translate(-9px, -58px); */
    }
  }
  




