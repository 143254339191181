/* Popup Form Container */
/* .popupform-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    margin: 0 auto;
    background: #f9f9f9; 
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .popupform-heading {
    text-align: center;
    color: #0056b3;
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .popupform-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .popupform-section label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    font-family: 'Roboto', sans-serif;
  }
  
  .popupform-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    outline: none;
    background: #fff; 
    transition: all 0.3s ease;
  }
  
  .popupform-input:focus {
    border-color: #0056b3;
    background-color: #f1f8ff; 
  }
  
  .popupform-container select.popupform-input {
    appearance: none;
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4z'/%3E%3C/svg%3E") no-repeat right 10px center;
    background-size: 10px;
  }
  
 
  .popupform-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .popupform-close:hover {
    color: #ff4d4d;
  }
  
  .popupform-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .popupform-submit {
    background-color: #007bff;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popupform-submit:hover {
    background-color: #0056b3;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .popup-container {
    position: relative;
    padding: 20px 7px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .closePopupButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #ff4d4d;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
  }
  
  .closePopupButton:hover {
    transform: scale(1.2);
    color: #ff0000;
  }
  .popup-button {
    padding: 10px 65px 21px 30px;
    display: flex;
    justify-content: space-around;
}

  .popup-button button {
    padding: 12px 24px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup-button button:hover {
    background-color: #0056b3;
  }
  
  .popupform-container {
    background: linear-gradient(to right, #e0f7fa, #b2ebf2); 
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  }

  .popup-container[id]::before {
    content: attr(id);
    position: absolute;
    top: -20px;
    left: 10px;
    font-size: 12px;
    color: gray;
  }

  
  @media (max-width: 600px) {
    .popup-container {
      padding: 15px;
    }
  
    .popupform-heading {
      font-size: 20px;
    }
  
    .popupform-section {
      gap: 10px;
    }
  
    .popupform-input {
      font-size: 12px;
      padding: 10px;
    }
  
    .popupform-submit {
      font-size: 14px;
      padding: 10px 20px;
    }
  
    .popupform-close {
      font-size: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .popupform-container {
      width: 90%;
      padding: 15px;
    }
  }
   */




   .popupform-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    margin: 0 auto;
    background: #f9f9f9; 
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .popupform-heading {
    text-align: center;
    color: #0056b3;
    font-size: 19px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .popupform-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .popupform-section label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    font-family: 'Roboto', sans-serif;
  }
  
  .popupform-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    outline: none;
    background: #fff; 
    transition: all 0.3s ease;
  }
  
  .popupform-input:focus {
    border-color: #0056b3;
    background-color: #f1f8ff; 
  }
  
  .popupform-container select.popupform-input {
    appearance: none;
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4z'/%3E%3C/svg%3E") no-repeat right 10px center;
    background-size: 10px;
  }
  
 
  .popupform-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .popupform-close:hover {
    color: #ff4d4d;
  }
  
  .popupform-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .popupform-submit {
    background-color: #007bff;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popupform-submit:hover {
    background-color: #0056b3;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .popup-container {
    position: relative;
    padding: 0px 7px;
    background: #fff;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .closePopupButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #ff4d4d;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
  }
  
  .closePopupButton:hover {
    transform: scale(1.2);
    color: #ff0000;
  }
  .popup-button {
    padding: 10px 65px 21px 30px;
    display: flex;
    justify-content: space-around;
}

  .popup-button button {
    padding: 12px 24px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup-button button:hover {
    background-color: #0056b3;
  }
  
  .popupform-container {
    background: linear-gradient(to right, #e0f7fa, #b2ebf2); 
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  }

  .popup-container[id]::before {
    content: attr(id);
    position: absolute;
    top: -20px;
    left: 10px;
    font-size: 12px;
    color: gray;
  }

  
.popup-content {
  margin: auto;
  background: #fff;
  width: 45% !important; 
}

.popupform-input.error {
  border: 2px solid red;
}


.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 4px;
}
  
  @media (max-width: 600px) {
    .popup-content {
      margin: auto;
      background: #fff;
      width: 80% !important;
  }
  .popupform-section label {
    font-size: 12px;
    font-weight: bold;
    color: black;
    font-family: 'Roboto', sans-serif;

  }


    .popup-container {
        padding: 2px;
        width: 100%;
        border-radius :0px
  
    }
  
    .popupform-heading {
      font-size: 17px;
    }
  
    .popupform-section {
      gap: 10px;
    }
  
    .popupform-input {
      font-size: 12px;
      padding: 10px;
    }
  
    .popupform-submit {
      font-size: 14px;
      padding: 10px 20px;
    }
  
    .popupform-close {
      font-size: 20px;
    }

    .closePopupButton {
      position: absolute;
      top: 3px;
      right: 10px;
      cursor: pointer;
      color: #256dac;
      font-size: 30px;
  }

  .popupform-wrapper {
    max-width: 318px;
  }
  }
  
  @media (max-width: 768px) {
    .popupform-container {
     
      padding: 15px;
      width: 100%;
      border-radius :0px
    }
  }
  



























  