
.animatestyle{
    margin-top: 10px;
    margin-bottom: 10px;
    color:azure;
    padding: 5px 5px 5px 9px;
    position: relative;
    bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}
.first span, .second span, .third span, .forth span{
    font-weight: bold;
    padding-left: 10px;
}
.scroll1{
    display: flex;    
}
.first, .second, .third, .forth{
    /* padding-right: 135px; */
    padding-right: 75px;
    font-size: 24px;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    /* .animatestyle{
    position:static;
    padding: 0;
    }
    .scroll1{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 30px;
    } */
    .scroll1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 30px;
        gap: 19px;
    }
    .animatestyle {
        position: static;
        padding: 5px 5px 5px 5px;
    }
    .second, .forth {
        position: relative;
        left: 34px;
    }
    .first,.second,.third,.forth{
        padding: 0;
        font-size:14px;
    color:black;
    }   
    .first-count, .second-count, .third-count, .forth-count {
        margin-bottom: 10px; 
        width:100%;
        margin-top: 10px;      
    }   
     
}
/* @media screen and (min-width: 769px) and (max-width: 1200px){ */
@media screen and (min-width: 769px) and (max-width: 820px) {
    /* .animatestyle{
       
        margin-top: 10px;
        margin-bottom: 10px;
        color:azure;
        padding: 5px 5px 5px 80px;
        position: relative;
        bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        
    } */

    .animatestyle {
        margin-top: 10px;
        margin-bottom: 10px;
        color: azure;
        padding: 5px;
        position: relative;
        bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .first, .second, .third, .forth {
        display: grid;
        grid-template-rows: 1fr;
        position: relative;
        bottom: 16px;
        padding-right: 0px;
        font-size: 20px;
        font-weight: bold;
        width: 186px;
        justify-content: space-evenly;
    }
    .first span, .second span, .third span, .forth span{
        font-weight: bold;
        display: flex;

    }
    .scroll1{
        display: flex;    
    }
    /* .first, .second, .third, .forth{
        display: grid;
        grid-template-rows: 1fr;
        position: relative;
        bottom: 16px;
        padding-right: 118px;
        font-size: 20px;
        font-weight: bold;
    } */
    
    
}



