

.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #fff; 
    padding: 10px 0;
  }

  .marquee-content{
    animation: marquee 40s linear infinite;
    display: flex;
    width: 340%;
    margin-left: 100px;
  }


  
  .marquee-content img {
    width: 100px; 
    margin: 0 20px; 

  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  

  .marquee-content::after {
    content: "";
    display: flex;
  }
  
  .marquee-content::after > img {
    content: attr(src);
    display: inline;
  }
  