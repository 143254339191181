.homePage{
    display:flex;
    align-items: center;
    justify-content: center;
    width:80%;
    margin:auto;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    margin-top: 10px;
}

.homePageImageContainer{
    width:50%;
    margin: 5%;
    
}
.HomePageImage{
    width:100%;
    align-items: center;

}
.homePageContent{
    width:50%;
    /* font-family: 'Times New Roman', Times, serif; */
    text-align: justify;
    margin:5%;
}
.homeParagraph{
    line-height: 22px;
}
.modal{
    margin:20px;
    text-align: justify;
}
.page-paragraph{
    line-height: 25px;
}

@media screen and (max-width: 768px) {

    .homePageContent{
        width:90%;
        text-align: justify;
        margin:5%;
        
    }
    .homePageImageContainer{
        width:100%;
        margin: 5%;
    }
    .homePage{
        display:flex;
        align-items: center;
        justify-content: center;
        margin:auto;
        width: 90%;
        flex-direction: column;
    }
    /* .homeParagraph, .homePageHeading{
        padding: 0px 25px;
    } */
    /* .homePageButton {
        padding-left: 25px;
    } */
}