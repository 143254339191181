.App {
  display: flex;
  flex-direction: column;
}



  .ubuntu-mono-regular {
    font-family: "Ubuntu Mono", monospace;
    font-weight: 400;
    font-style: normal;
  }


/* .ubuntu-mono-bold {
  font-family: "Ubuntu Mono", monospace;
  font-weight: 700;
  font-style: normal;
} */

/* .ubuntu-mono-regular-italic {
  font-family: "Ubuntu Mono", monospace;
  font-weight: 400;
  font-style: italic;
} */






