
.services-container {
  display: flex;
  justify-content: space-between; 
  flex-wrap: nowrap; 
  align-items: center;
  margin: 20px;
}

.service-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 15%; /* Adjusts the width of each box */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.service-icon {
  font-size: 40px;
  margin-bottom: 10px;
  color: #333;
}

.service-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.service-btn:hover {
  background-color: #0056b3;
}


.service-header{
  height: 47px;
  text-align: center;
  font-size: 17px;
}
h3{
  font-size: 17px;
}
@media screen and (max-width: 768px) {
  .services-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
    align-items: center;
    margin: 20px;
  }

  .service-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 200PX;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 9px;
}
}

@media screen and (min-width: 769px) and (max-width: 980px){
  .services-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px;
}   
}