.searchJob {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.8rem;
  background-color: hsl(0deg 0% 98%);
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 16px;
}

.searchJob:hover {
  border: 1px solid #0fceff;
}
.designation {
  
  
  /* font-weight: bold; */
  font-size: 16px;
}

.company {
  color: hsl(218deg 66% 30%);
  font-size:20px;
  font-weight: 600;
  padding: 2px 0px;
}
.modal-open {
  overflow: hidden;
}
.closePopupButton {
  position: absolute;
  top: 0px;
  right: 1px;
  cursor: pointer;
  color: #256dac;
  font-size: 30px;
}
/* .closePopupButton {
  position: absolute;
  top: 10px; 
  right: 10px; 
  cursor: pointer;
  color:#256dac;
  font-size: 30px;
} */
.vacancy {
  display: flex;
  flex-direction: row-reverse;
}
.separator {
  color: hsl(0deg 0% 82%);
  padding: 2em;
}
.separator1{
  color: hsl(0deg 0% 82%);
  padding:2em;
}
.apply-close-btn {
  display: flex;
  justify-content: space-between;
}
.jobPortal {
  position: relative;
}
.apply-close-btn Button:hover{
  background-color: aqua; 
  color: black;
}
.handleJobClick Button:hover{
  background-color: aqua; 
  color: black;
}
.jobPortal {
  position: relative;
}
.lock-location-icon svg{
  transform:translate(0px,2px);
  padding-right: 5px;
}

@media screen and (max-width: 768px) {
  .list-icon .icons {
    width: 0;
    height: 0;
    padding: 20px;
    margin: 15px;
    display: flex;
  }
  .list-icon {
    display:flex;
  }
}
