.custom-modal {
    max-height: 70vh; 
    max-height: 70vh; 
  }
  
  .custom-modal-content {
    overflow-y: auto;
  }
  .popup-display{
    padding:11px 29px 0px 29px;
    text-align: justify;
    
  }
  .popup-display .page-heading{
    color: #256dac;
  }
  .popup-button{
    padding:10px 65px 21px 30px;
  }
  .popup-content {
    margin: auto;
    background: #fff;
    width:30%;
    border: 1px solid #d7d7d7;
} 

.popup-button Button:hover{
  background-color: aqua; 
  color: black;
}

.popup-display Button:hover{
  background-color: aqua; 
  color: black;
}
.homePageButton Button:hover{
  background-color: aqua; 
  color: black;
}
.popup-container{
  max-height: 90vh; 
  overflow-y: auto;
   
}
.popup-container {
  position: relative;
}

.closePopupButton {
  position: absolute;
  top: 10px; /* Adjust this value to change the vertical position */
  right: 10px; /* Adjust this value to change the horizontal position */
  cursor: pointer;
  color:#256dac;
  font-size: 30px;
}

.closePopupButton:hover {
  color:black;
}
.modal-open{
  overflow: hidden;
}
@media screen and (max-width: 768px){
  .popup-display Button:hover{
    background-color: aqua; 
    color: black;
  }
  .popup-container{
    width: 98%;
  }
  .homePageButton Button:hover{
    background-color: aqua; 
    color: black;
  }
  .popup-button Button:hover{
    background-color: aqua; 
    color: black;
  }
  .popup-display{
    padding: 10px;

  }
}