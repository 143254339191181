.contactpg {
  /* font-family: "Anek Latin", sans-serif; */
  color: rgb(20, 13, 3);
  box-sizing: border-box;
  padding: 55px;
  width: auto;
}
.copyRights{
  font-size: smaller;
  color: #256dac;
  margin-top: 2%;
  text-align: center;
  margin-bottom:2%;
}
.contact {
  display: grid;
  column-gap: 5px;
  grid-template-columns: auto auto auto;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
  margin: 15px;
}

.contact1,
.contact2,
.contact3 {
  margin: 14px;
  border: 2px solid white;
  border-radius: 15px;
  box-shadow: 4px 4px 4px 4px rgb(79, 79, 108);
  transition: box-shadow 0.3s;
}
:hover {
  box-shadow: rgba(33, 33, 33, 0.2);
}
/* .contact2{
    padding-top: 22px;
} */
.follows {
  /* min-height: 200px; */
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
  border-radius: 50px;
}
.headingfollow {
  text-align: center;
  font-size: 30px;
  color: #256dac;
}
.comname {
  margin-bottom: 20px;
  line-height: 0.2;
  margin: auto;
  color: #256dac;
  font-size: 17px;
}
.contactus {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: #256dac;
}
.follows-list-icon {
  display: inline-flex;
  list-style-type: none;
}
.contact2-content {
  padding: 10px;
}

.follows-list-icon .icons {
  position: relative;
  padding-right: 35px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.icon-mail {
  font-size: 33px;
  color: #256dac;
  margin-bottom: 20px;
}
.icon-contact {
  color: #256dac;
  font-size: 33px;
}
.icon-home {
  margin-bottom: 20px;
  font-size: 33px;
}

.contact-para{
  font-size: 17px;
}
/* .contact2 svg{
  font-size: 33px;
} */
@media screen and (max-width: 768px) {
  .follows-list-icon{
    padding: 0;
  }
  .follows-list-icon .icons{
    padding:0;
  }
  .follows-list-icon .fb, .follows-list-icon .linkedin{
    padding-right : 65px ;
  }
  .contact {
    display: flow;
    padding: 18px;
    font-size: 13px;
    text-align: center;
    margin: 0px;
  }
  .icon-home {
    margin: 0px 0px 20px;
    font-size: 33px;
  }
  .follows-list-icon {
    padding-left: 18px;
  }

  .contactpg {
    /* font-family: "Anek Latin", sans-serif; */

    box-sizing: border-box;
    padding: 0;
    width: 90%;
    margin: auto;
  }
}
@media screen and (min-width: 820px) and (max-width: 1024px){
  /* .contactpg {
    color: rgb(20, 13, 3);
    box-sizing: border-box;
    padding: 55px;
    width: auto;
  } */
  .contactpg {
    color: rgb(20, 13, 3);
    box-sizing: border-box;
    padding: 29px 7px;
    width: auto;
}
  .contact {
    display: grid;
    column-gap: 5px;
    grid-template-columns: auto auto auto;
    padding: 20px 5px;
    font-size: 15px;
    text-align: center;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    margin: 11px;
  }
  
  .contact1,
  .contact2,
  .contact3 {
    margin: 14px;
    border: 2px solid white;
    border-radius: 15px;
    box-shadow: 4px 4px 4px 4px rgb(79, 79, 108);
    transition: box-shadow 0.3s;
  }
  :hover {
    box-shadow: rgba(33, 33, 33, 0.2);
  }
  /* .contact2{
      padding-top: 22px;
  } */
  .follows {
    /* min-height: 200px; */
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    border-radius: 50px;
  }
  .headingfollow {
    text-align: center;
    font-size: 30px;
    color: #256dac;
  }
  .comname {
    margin-bottom: 20px;
    line-height: 0.2;
    margin: auto;
    color: #256dac;
  }
  .contactus {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #256dac;
  }
  .follows-list-icon {
    display: inline-flex;
    list-style-type: none;
  }
  .contact2-content {
    padding: 10px;
  }
  
  .follows-list-icon .icons {
    position: relative;
    /* background-color: beige; */
    padding-right: 35px;
    /* padding: 10px; */
    /* height: 50px; */
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    /* margin: 30px; */
  }
  .icon-mail {
    font-size: 30px;
    color: #256dac;
  }
  .icon-contact {
    color: #256dac;
   
  }
  .icon-home {
    margin-bottom: 20px;
  }

}