*{
font-family:'Times New Roman', Times, serif;
}
.aboutPage{
  display:flex;
    align-items: center;
    justify-content: center;
    width:80%;
    margin:auto;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    margin-top: 10px;
}
.aboutImageContainer{
  width:50%;
  margin: 5%;
  
}

.aboutPageImage{
  width:100%;
  align-items: center;

}
.aboutPageContent{
  width:50%;
  font-family: 'Times New Roman', Times, serif;
  text-align: justify;
  margin:5%;
}

  .principles{
    
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    width:80%;
    margin:auto;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    margin-top: 10px;
  }
  .principles-list{
    text-align:justify;
    
    align-items: center;
    margin:8px;
  }
  .principles-title{
    text-align:center;
  }
  .About-images img{
    width:100%;
    height:auto;
  }

.end-line{
  text-align: center;
  padding-bottom: 84px;
}

.page-heading{
  color:#256dac;
  text-align:center;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {

  .aboutPageContent{
      width:90%;
      /* font-family: 'Times New Roman', Times, serif; */
      text-align: justify;
      margin:5%;
      
  }
  .aboutImageContainer{
      width:100%;
      margin: 5%;
  }
  .aboutPage{
      display:flex;
      align-items: center;
      justify-content: center;
      margin:auto;
      width: 80%;
      flex-direction: column;
  }
  .principles{
    
    display:grid;
    grid-template-columns: 1fr ;
    width:80%;
    margin:auto;
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    margin-top: 10px;
  }
}