.signupform {
    position: fixed;
    right: 13px;
    top: 90px;
    width: 23%;
    height: 74%;
    border: 2px solid #007bff;
    background-color: #f9f9f9;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    padding: 2px 0px 0px 17px;
    z-index: 1000;
    animation: slideIn 0.8s ease-in-out;
}

.signup-heading {
    font-size: 13px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 15px;
}

.signup-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signup-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.signup-section {
    width: 90%;
    margin-bottom: 5px;
    margin: auto 5px;
}

.signup-input {
    width: 96%;
    padding: 7px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 12px;
}

.signup-input.error {
    border-color: #e74c3c;
}

.popupcss-label {
    font-size: 12px;
    font-weight: 600;
}

.signup-error-message {
    color: #e74c3c;
    font-size: 12px;
    margin-top: 5px;
}

select.signup-input {
    background-color: #fff;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    background-color: #007bff;
    border-radius: 50px;
}

.signup-submit-button {
    display: block;
    width: 85%;
    padding: 6px 0px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    margin: 12px 0px 0px 7px;
}

.form-header {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}


/* For devices with max-width: 600px */
@media (max-width: 320px) {
  .signupform {
       width: 77vw;
       height: 52vh;
  }
  .signup-heading {
      font-size: 16px;
  }
  .signup-input {
      font-size: 12px;
  }
  .signup-submit-button {
      width: 90%;
      font-size: 14px;
  }
}



@media (min-width: 321px) and (max-width: 600px) {
  .signupform {    
    padding: 2px 6px 0px 5px;
      width: 45vw;
      height: 52vh;
  }
  .signup-heading {
    font-size: 10px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 15px;
    /* gap: 0px; */
}
.form-header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: start;
    gap: 5px;
}
}

/* For devices between 601px and 768px */
@media (min-width: 601px) and (max-width: 768px) {
  .signupform {
    width: 40vw;
    height: 50vh;
  }
  .signup-heading {
      font-size: 18px;
  }
  .signup-input {
      font-size: 14px;
  }
  .signup-submit-button {
      width: 95%;
  }
}

/* For devices between 769px and 820px */
@media (min-width: 769px) and (max-width: 820px) {
  .signupform {
      width: 35vw;
      height: 39vh;
  }
  .signup-heading {
      font-size: 16px;
  }
  .signup-input {
      font-size: 13px;
  }
  .signup-submit-button {
      width: 90%;
  }
}

/* For devices between 821px and 920px */
@media (min-width: 821px) and (max-width: 920px) {
  .signupform {
    width: 38vw;
    right: 80px;
    height: 33vh;
  }
  .signup-heading {
      font-size: 15px;
  }
  .signup-input {
      font-size: 13px;
  }
  .signup-submit-button {
      width: 85%;
  }
}

/* For devices between 921px and 1024px */
@media (min-width: 921px) and (max-width: 1024px) {
  .signupform {
      width: 33vw;
      height: 33vh;
  }
  .signup-heading {
      font-size: 16px;
  }
  .signup-input {
      font-size: 13px;
  }
  .signup-submit-button {
      width: 80%;
  }
}
