.NavigationBar {
    /* font-size: 21px; */
    font-size: 16px;
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 2px 2px rgba(76, 74, 74, 0.23);  
  } 
  .hamburger {
    display: none;
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
  .NavBar-list{
    list-style-type: none;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    /* font-size: 20px; */
    color:#0158a1;
    /* padding-left: 39px; */
  }
  .Nav-components{
    width: 46%;
  }
  .Nav-components ul{
    display:flex;
    justify-content: space-around;
    /* justify-content: flex-end; */
    padding:0px;
  }
  .Nav-components li:hover {
    display: inline;  
    text-decoration: none;
    cursor: pointer;
  } 
  

.courses-container {
    position: relative;
  }
  
  .HeroSlider {
    width: 100%;
  }
  .Logo {
    width: 35%;
    transform: translate(10px, 10px);
  }
  .LogoContainer {
    width: 50%;
  }
  
  .hamburger:hover {
    background-color: #2642af;
  }

  a:link,
  a:visited,
  a:hover,
  a:active{
    color:#0158a1;
  }

  .nav-signup-button{
    width: 100%;
    padding: 5px 14px;
    background-color: #007BFF;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0px;
  }



  @media screen and (max-width: 768px) {

    .hamburger {
      display: block;
    }
  
    .NavBar-list ul Button{
      color: aqua;
    }
    .Nav-components ul {
      display: none;
      position: absolute;
      top: 60px; /* Adjust according to your design */
      left: 0;
      flex-direction: column;
      width: 100%;
      background-color: white;
      padding: 0;
      margin: 0;
      z-index: 1; /* Ensure it appears above other content */
    }
  
    .Nav-components.expanded ul {
      display: flex;
    }
  
    .NavBar-list {
      /* width: 100%; */
      width: auto;
      text-align: center;
      padding-bottom:15px;
      /* padding-left: 39px; */
      padding-left: 0px;
    }
    
    .Logo {
      width: 74%;
  
    }
  }
  
  
  @media screen and (min-width:768px) and (max-width:820px){
    .NavigationBar{
      height: 67px;
    }
    .Logo{
      width: 80%;
    }

    .LogoContainer {
      width: 47%;
  }
  .Nav-components {
    width: 70%;
}
  }
  
  
  @media screen and (min-width: 820px) and (max-width: 926px){
  
    .Nav-components ul{
      display:flex;
      justify-content: space-between;
      padding:0px;
    
      
    }
    .Nav-components{
      width: 50%;
    }
    
     .NavBar-list{
      list-style-type: none;
      text-decoration: none;
      display: block;
      text-transform: uppercase;
      font-weight: 600;
      /* font-size: 18px; */
      font-size: 16px;
      color:#0158a1;
      padding-left: 0px;
    }
    
    
    
    .NavigationBar {
      font-size: 16px;
      /* font-size: 21px; */
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: space-around;
      background-color: rgba(255, 255, 255, 0.5);
      position: relative;
      z-index: 1;
      box-shadow: 0 2px 2px 2px rgba(76, 74, 74, 0.23);
    
    } 
    .Nav-components li:hover {
      display: inline;  
      text-decoration: none;
      cursor: pointer;
    } 
    
    .courses-container {
      position: relative;
    }

    .Logo {
      width: 44%;
      transform: translate(10px, 17px);
    }
    .LogoContainer {
      width: 50%;
    }
    .hamburger {
      display: none;
      border: 0;
      height: 40px;
      width: 40px;
      padding: 0.5rem;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
    }
    .hamburger:hover {
      background-color: #2642af;
    }
    .Nav-components {
      width: 56%;
  }
  }
  @media screen and (min-width: 927px) and (max-width:1024px){
    .Logo{
      width: 44%;
      transform: translate(10px, 15px);
    }
    .NavBar-list{
      padding-left: 18px;
    }
    .Nav-components {
      width: 54%;
  }
  .LogoContainer {
    width: 44%;
}
  }