.admin-csvfile {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    position: relative;
    top: 150px;
    margin-left: 33px;
}