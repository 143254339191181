.courses{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 25px;
    font-size: 22px;
    min-width: 150px;
}
.itCourse Button , .nonItCourse Button{
    font-size: 1rem;
    min-width: 120px;
    padding: 13px 45px;
}
.itCourse Button:hover, .nonItCourse Button:hover {
    background-color: aqua; 
    color: black;
}
@media screen and (max-width: 768px){
    .courses {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        align-content: center;
    }
    .itCourse{
        padding: 3px 5px 12px 45px;
    }
    
    .nonItCourse {
        padding: 25px 25px; 
    }
    .itCourse Button , .nonItCourse Button{
        font-size: 1rem;
        min-width: 100px;
        padding: 10px 21px;
    }
    
    
    Button.itCourse, Button.nonItCourse {
        font-size: 1rem;
        min-width: 100px;
        padding: 10px 21px; 
    }
    .itCourse Button:hover, 
.nonItCourse Button:hover,
.itCourse Button:focus, 
.nonItCourse Button:focus,
.itCourse Button:active, 
.nonItCourse Button:active {
    background-color: aqua; 
    color: black;
}

    /* .itCourse Button:hover, .nonItCourse Button:hover {
        background-color: aqua; 
        color: black;
        
    } */
}
