.email-sending {
    max-width: 400px;
    margin: 68px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fafafa;
  }
  
  .input-box{
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-family: Arial, sans-serif;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  button:hover {
    background-color: #0056b3;
  }
  .email-container{
    display: flex;
    justify-content: space-around;
  }
  .email-label{
    width: 181px;
  }
  .email-head{
    text-align: center;
  }

  .error {
    border: 1px solid red;
  }
  
  span.error-message1 {
    color: red;
    display: flex;
    justify-content: center;
}

input.input-box.error1 {
  border: 2px solid red;
}
.upload-input{
  width: 80%;
  
}
.upload-button{
  font-size: 12px;
  padding: 2px 5px 0px 4px;
  height: 42px;
}