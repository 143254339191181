.sliderImageSize {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 103vw;
  /* width: 100vw; */
  height: 255px;
  background-repeat: round;  
}


@media screen and (min-width: 820px) and (max-width: 926px){
  .sliderImageSize {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 255px;
    background-repeat: round;  
  }
  
  .HeroSlider {
    width: 100%;
  }
}













