*{
    /* font-family: "Raleway", sans-serif; */
    font-family: "Anek Latin", sans-serif;
} 
.container{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;  
    padding: 30px 15px 0px;
    margin: 0px 0px 48px;  
}
.contain{
    box-sizing: border-box;
    padding: 25px;
    border-radius: 6%;
    border: #000 solid 1px;
    padding: 15px 15px  ;
    margin-left: 5%;
    margin-bottom: 5%;  
    text-align: center;   
}
.services_description{
    line-height: 24px;
 
    text-align: justify;
}
.heading{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #256dac;
    text-align: center;
}
.icon{
    display: flex;
    justify-content: center;
    margin: 2px;
    width: 100px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: #65bfab;
    font-size: 40px;
    overflow: hidden;
    padding-top: 20px;
    box-shadow: 0px 0 25px #2642af;  
}
p .icon{
    position: relative;
    top: 50px;
    width: 59px;
    height: 180px;    
}
.headingh1{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #256dac;
    text-align: center;
}
/* .para1{
    
    text-align: center;
} */
.process{
    box-sizing: border-box;
    background: #000;
    color: #fff;
}
.our_procesh1{
  padding: 6%;
    text-align: center; 
    color: #fff;
}
.progress{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;   
    padding-top: 1rem;
    margin: 0px -15px;      
}
.icon2{
    display: flex;  
    justify-content: center;
    text-align: center;
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
    border-radius: 50%;
    transition: 0.5s;   
    font-size: 40px;
    padding-top: 20px;
    box-shadow: 0px 0 25px #2642af;
}
.headingg{
    color: #fff;  
    text-align: center;
}
.card-bottom a {
    /* color: #cc0f0f; */
    font-size: 17px;
    font-weight: 600;
}
a {
    text-decoration: none!important;
    outline: 0;
    background-color: transparent;
    /* transition: .4s ease-in-out */
}
a:-webkit-any-link {   
    cursor: pointer;
    text-decoration: underline;   
}
.card-bottom, .clients-btn {
    padding-top: 30px;
    padding-left: 40%;
    padding-bottom: 20%;
}
.services-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.para1-container{
    text-align: center;
    width:90%;
    margin:auto;
}
@media screen and (max-width:768px){
    .para1-container{
        text-align: justify;
        width:90%;
        margin:auto;
    }
    .container{
        word-spacing: 1px;
        display: grid;
        grid-template-columns: 1fr;
    }
    .contain .icon{
        display: none;
    }
    .contain{
        margin-left: 0;
    }
}